




































import Vue from 'vue';
import SiHaDocumentationResourceViewModel from '@/src/services/viewModel/resource/SiHaDocumentationResourceViewModel';
import UploadDocumentRequestViewModel from '@/src/services/viewModel/request/Documents/UploadDocumentRequestViewModel';

export default Vue.extend({
  auth: true,
  props: {
    value: { type: Object, default: undefined },
  },
  data: () => ({
    isLoading: false,
    fileConstructionPlan: undefined as undefined | File[],
    fileLocationMap: undefined as undefined | File[],
  }),
  computed: {
    innerValue: {
      get(): SiHaDocumentationResourceViewModel {
        return this.value ?? {};
      },
      set(value: SiHaDocumentationResourceViewModel) {
        this.$emit('input', value);
      },
    },
  },
  watch: {
    innerValue: {
      immediate: true,
      async handler() {
        if (this.innerValue.locationMapDocumentId) {
          this.isLoading = true;
          this.fileLocationMap = [];
          const image = await this.$service.api.documents.downloadImage(this.innerValue.locationMapDocumentId);
          this.fileLocationMap.push(image as File);
          this.isLoading = false;
        }
        if (this.innerValue.constructionPlanDocumentId) {
          this.isLoading = true;
          this.fileConstructionPlan = [];
          const image = await this.$service.api.documents.downloadImage(this.innerValue.constructionPlanDocumentId);
          this.fileConstructionPlan.push(image as File);
          this.isLoading = false;
        }
      },
    },
  },
  methods: {
    async onConstructionPlanFileSelected(files: File[]) {
      const [file] = files;
      let id = undefined as number | undefined;
      if (file) {
        const req = new UploadDocumentRequestViewModel();
        req.uploadFile = file;
        id = await this.$service.api.documents.uploadDocument(req);
      }
      this.innerValue.constructionPlanDocumentId = id;
    },
    async onLocationMapFileSelected(files: File[]) {
      const [file] = files;
      let id = undefined as number | undefined;
      if (file) {
        const req = new UploadDocumentRequestViewModel();
        req.uploadFile = file;
        id = await this.$service.api.documents.uploadDocument(req);
      }
      this.innerValue.locationMapDocumentId = id;
    },
  },
});
